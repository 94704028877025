import React, { Component } from 'react';
import {NavBar} from "./NavBar";
import {About} from "./About";
import {Name} from "./Name";
import {Skills} from "./Skills";
import {Portfolio} from "./Portfolio";
import {Contact} from "./Contact";
import {Education} from "./Education";



export function Home() {
    return (
        <div id="about">
            <NavBar />
            <Name />
            <About />
            <Portfolio />
            <Skills />
            <Education />
            <Contact />
        </div>
    );
}