import React, { Component } from 'react';
import styled from 'styled-components';
import Card from 'react-bootstrap/Card';
import Nav from "react-bootstrap/Nav";
import {TitleText} from "./About";
import Fade from "react-reveal/Fade";
import Modal from "react-modal";
import {SmallText} from "./Portfolio";
import {NavBar} from "./NavBar";
import GitHubIcon from "@material-ui/icons/GitHub";
import Button from "@material-ui/core/Button";


export function VV() {
    function linkGitHub() {
        window.open(
            "https://github.com/ccsfaiclub/violence-tracker", "_blank");
    }
    return (
        <div>
            <Fade bottom distance='20%'>
                <Card bg="white"  style={{ margin: '1rem', border: 'solid #2f2f2f'}}>
                    <Card.Body style={{position: 'relative'}}>
                        <Card.Title className='ml-5 my-5'>
                            <TitleText><a href="https://hoeunsim.com/vt/" target="_blank" rel="noopener noreferrer">VIOLENCE-VISUALIZER
                                <Button style={{float: 'right'}} onClick={linkGitHub} size="large">
                                    <GitHubIcon style={{fontSize: '2.3em'}}></GitHubIcon>
                                </Button></a></TitleText>
                        </Card.Title>
                        <Card.Text className='mx-5' style={{fontSize: '1em'}}>
                            <p>Following the death of George Floyd, a wave of civil unrest escalated across the nation. As thousands of protesters took to the
                                streets, demanding an end to racial injustice and police brutality, evidence of violent police crackdowns
                                on peaceful demonstrators began circulating the web. Since May, 2020, over <a href="https://www.reddit.com/r/2020PoliceBrutality/" target="_blank" rel="noopener noreferrer">1,200 incidents have been documented</a>,
                                with videos showing police engaging in unprovoked and unnecessary harassment, assaults, and brutality. <br/><br/>

                                To highlight the policing crisis, I helped lead a team of aspiring developers from City College of San Francisco’s AI (Artificial Intelligence) Club
                                to build Violence-Visualizer, a web app for visualizing and tracking reported incidents of police violence during the 2020 George Floyd protests.
                                The app displays the incidents as markers on an interactive map. Visitors may learn more about each incident by clicking on the map markers,
                                which will open a sidebar containing more detailed information about the incident, including sources and related evidence.<br/><br/>
                            </p>
                            <hr></hr>
                            <SmallText>
                                <p><b>Front-end</b>: ReactJS | JavaScript | Leaflet | HTML5 | CSS | Figma | Material-UI</p>
                                <p><b>Back-end</b>: Python | Pytest | Flask | GraphQL | JSON | PostgreSQL | SQLAlchemy</p>
                                <p><b>Tools</b>: Git | GitHub | npm | GNU Make | Trello | UNIX/Linux | Docker | IntelliJ IDEA | Vim</p>
                            </SmallText>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Fade>
            <hr style={{borderTop: '2px solid #bbb', width: '90%'}}></hr>
        </div>
    );
}