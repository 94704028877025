import React, { Component } from 'react';
import styled from 'styled-components';
import Card from 'react-bootstrap/Card';
import Fade from "react-reveal/Fade";


const PrettyText = styled.p`
    color: #222;
    letter-spacing: 20px;
    font-size: 5em;
    margin: 3vw;
//     padding-top: 5rem;
    text-align: center;
    font-weight: 300;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);

    transform: scaleY(1.2);
    text-transform: uppercase;
`;

export function Name() {
    return (
        <div id="Name">
        <Fade up duration={1000} distance='10%'>
            <PrettyText>Hoeun Sim </PrettyText>
            <hr style={{borderTop: '3px solid #bbb',
                        width: '90%'
                        }}></hr>
                        </Fade>
        </div>
    );
}