import React, { Component } from 'react';
import styled from 'styled-components';
import Card from 'react-bootstrap/Card';
import Nav from "react-bootstrap/Nav";
import Fade from "react-reveal/Fade";
import Button from "@material-ui/core/Button";
import { Link, animateScroll as scroll } from "react-scroll";


export const TitleText = styled.p`
    font-weight: normal;
    color: #222;
    letter-spacing: 4px;
    font-size: 3.5vw;
    margin-top: 6vw;
`;



export function About() {
    return (
        <div id="about">
            <Fade bottom duration={1000} distance='10%'>
            <div>
                <Card bg="white" className="mx-auto" style={{ margin: '2rem', display: 'flex', flexDirection: 'row', width: '90%', border: 'none'}}>
                    <Card.Img variant="left" src="/hoeun-canyon.jpg" alt="Hoeun"  height='50%' width="50%" object-fit='cover'/>
                    <Card.Body variant="right">
                        <Fade up delay={1}> <Card.Title className='ml-5 my-5'><TitleText>Hi, I'm Hoeun!</TitleText></Card.Title> </Fade>
                        <Card.Text className='mx-5' style={{fontSize: '1.5vw', letterSpacing: '.09em', width: '75%', height: '50%'}}>

                            <Fade up delay={300}><p>I’m an aspiring full-stack Software Engineer with a background in Research and Experimental Psychology, based in San Francisco (and originally from Cambodia).</p><br/></Fade>
                            <Fade up delay={500}><p>I like to explore and make things--I’m happiest when I’m learning new technologies and creating products that help people. :)</p><br/><br/></Fade>
                            <Fade up delay={700}>
                            <p>
                                <Link to="portfolio" smooth={true}>
                                    <Button size="large" variant="outlined" style={{color: '#2D68C4', border: 'solid 3px', textTransform: 'inherit', fontSize: '1.5vw'}}>Check out my work!</Button>
                                </Link>
                            </p>
                            </Fade>
                        </Card.Text>
                    </Card.Body>
                </Card>
                {/*<hr style={{borderTop: '3px solid #bbb', width: '90%'}}></hr>*/}
            </div>
            </Fade>
        </div>
    );
}