import React from 'react';
import ResponsiveGallery from 'react-responsive-gallery';
import {Header, SmallText, Title} from "./Portfolio";
import Fade from "react-reveal/Fade";
import {Link} from "react-router-dom";
import Card from "react-bootstrap/Card";
import Modal from "react-modal";
import {TitleText} from "./About";


export function Education() {

    return (
        <div id="About" >
            <Header>
                Education
                <hr style={{borderTop: '2px solid #bbb', width: '90%'}}></hr>
            </Header>
            <Fade bottom>
                <Header className='mx-5' style={{letterSpacing: '.1em', fontSize: '2vw', margin: '2em', color: 'grey'}}>
                   From social science to computer science, I've learned a few things along the way.
                </Header>
            </Fade>
            <Fade bottom distance='20%'>
                <div class="container-fluid">
                    <div class="row" style={{margin: '1em', padding: '2em', paddingRight: '4em', display: 'flex'}}>
                        <Fade bottom duration={1000}>
                        <div className="col-sm" style={{ width: '18rem'}}>
                                <Card style={{ margin: '1rem', border: 'none 1px #888888'}}>
                                    <Card.Img  variant="top" src="/ucsb.jpeg" alt="ucsb" height='50%' width="50%" object-fit='cover'/>
                                    {/*<Card.Img variant="top" src="/ucsb-label.png" alt="rr-label" height='50%' width="50%" object-fit='cover'/>*/}

                                    <Card.Body style={{objectFit: 'cover' }}>
                                        <Card.Title>University of California, Santa Barbara</Card.Title>
                                        <Card.Subtitle>
                                            B.A. Psychological & Brain Sciences | 2011
                                        </Card.Subtitle>
                                        <Card.Text>
                                            <br/><p>Awards</p>
                                            <hr style={{borderTop: '1px solid #bbb', width: '100%'}}></hr>
                                            Graduated with highest honors, <i>summa cum laude</i><br/><br/>
                                            Recipient of the <i>Exceptional Academic Performance Award </i>
                                            in the Department of Psychological & Brain Sciences | 2011
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                        </div>
                        </Fade>
                        <Fade bottom duration={1200}>
                        <div className="col-sm" >
                            <Card style={{ margin: '1rem', border: 'none 1px #888888'}}>
                                <Card.Img  variant="top" src="/sjsu.png" alt="sjsu" height='50%' width="50%" object-fit='cover'/>

                                <Card.Body style={{objectFit: 'cover' }}>
                                    <Card.Title>San José State University</Card.Title>
                                    <Card.Subtitle>
                                        M.A. Research & Experimental Psychology | 2016
                                    </Card.Subtitle>
                                    <Card.Text>
                                        <br/><p>Publications</p>
                                        <hr style={{borderTop: '1px solid #bbb', width: '100%'}}></hr>
                                        <a href="https://doi.org/10.1080/02699931.2018.1436043" target="_blank">
                                            Blocking Incidental Frustration During Bargaining</a><br/>
                                            Journal of Cognition and Emotion | 2018 <br/><br/>

                                        <a href="https://doi.org/10.31979/etd.qpqx-9r59" target="_blank">
                                            The Effects of Discrete Emotions on Risky Decision Making</a><br/>
                                            Master's Thesis | 2016 <br/>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        </Fade>
                        <Fade bottom duration={1400}>
                        <div className="col-sm">
                            <Card style={{ margin: '1rem', border: 'none 1px #888888'}}>
                                <Card.Img  variant="top" src="/ccsf.png" alt="ccsf" height='50%' width="50%" object-fit='cover'/>

                                <Card.Body style={{objectFit: 'cover' }}>
                                    <Card.Title>City College of San Francisco</Card.Title>
                                    <Card.Subtitle>
                                        Computer Science | 2019
                                    </Card.Subtitle>
                                    <Card.Text>
                                        <br/><p>Certifications</p>
                                        <hr style={{borderTop: '1px solid #bbb', width: '100%'}}></hr>
                                        Web Application Programming<br/><br/>
                                        Computer Programming: Java<br/><br/>
                                        Computer Programming: C++<br/>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        </Fade>
                    </div>
                </div>
            </Fade>
            {/*<hr style={{borderTop: '3px solid #bbb', width: '90%'}}></hr>*/}
            {/*<hr style={{borderTop: '2px solid #bbb', width: '90%'}}></hr>*/}
            {/*<Fade bottom distance='20%'>*/}
            {/*    <div style={{width: '60%', marginLeft: '20%'}}>*/}
            {/*        <ResponsiveGallery images={images}/>*/}
            {/*    </div>*/}
            {/*</Fade>*/}
            {/*<hr style={{borderTop: '3px solid #bbb', width: '90%'}}></hr>*/}

        </div>
    );
}


