import './App.css';
import React from "react";
import {NavBar} from "./components/NavBar"
import {Name} from "./components/Name"
import {About} from "./components/About"
import 'bootstrap/dist/css/bootstrap.min.css';
import {Portfolio} from "./components/Portfolio";
import {Blog} from "./components/Blog";
import {Contact} from "./components/Contact";
import {Skills} from "./components/Skills";
import {RR} from "./components/RR";
import {VV} from "./components/VV";
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import {Home} from "./components/Home";



export default function App() {

  return (
      <Router>
          <div>
             {/*<Home />*/}
              <Switch>
                  {/*Renders each project in projection section but cannot use HaskLink; only Link*/}
                  {/*<Route exact path='/' component={Portfolio} />*/}
                  {/*/!*<Route path="/portfolio" component={Portfolio} />*!/*/}
                  {/*<Route path="/portfolio/recipe-recommender" component={RR} />*/}
                  {/*<Route path="/portfolio/violence-visualizer" component={VV} />*/}
                  {/*<Route component={Portfolio} />*/}

                  <Route exact path='/' component={Home} />
                  <Route path='/about' component={Home} />
                  <Route path='/portfolio' component={Portfolio} />

                  {/*<Route path='/portfolio/recipe-recommender' component={RR} />*/}
                  {/*<Route path='/portfolio/violence-visualizer' component={VV} />*/}
                  <Route path='/contact' component={Contact} />

              </Switch>

              <Switch>
                  <Route exact path='/' component={Portfolio} />
                  {/*<Route path="/portfolio" component={Portfolio} />*/}
                  <Route path="/portfolio/recipe-recommender" component={RR} />
                  <Route path="/portfolio/violence-visualizer" component={VV} />
                  {/*<Route component={Portfolio} />*/}
              </Switch>
             {/*<Skills />*/}
             {/*<Contact />*/}
          </div>
      </Router>

  );
}
