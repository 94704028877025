import React, { Component } from 'react';
import styled from 'styled-components';
import Card from 'react-bootstrap/Card';
import Nav from "react-bootstrap/Nav";
import {TitleText} from "./About";
import Fade from "react-reveal/Fade";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";
import {withRouter} from 'react-router';


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from "react-router-dom";
import {RR} from "./RR";
import {Skills} from "./Skills";
import {NavBar} from "./NavBar";



export const Header = styled(TitleText)`
    text-align: center;
    margin: 1px;
    // font-size: 12px;  
`;

export const Title = styled(TitleText)`
    text-align: center;
    margin: 2px;
    padding: -1px;
    font-size: 20px;
    margin-right: 5px;

`;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '90%',
        maxHeight: '90%'

    },
    overlay: {zIndex: 1000}
};

export const SmallText = styled.div`
    text-align: left;
    // margin: 1rem;
    // padding: -1px;
    font-size: 14px;

`;

export function Portfolio() {
    const [rrmodalIsOpen, rrsetIsOpen] = React.useState(false);
    function rropenModal() {
        rrsetIsOpen(true);
    }

    function rrcloseModal(){
        rrsetIsOpen(false);
    }

    const [vvmodalIsOpen, vvsetIsOpen] = React.useState(false);
    function vvopenModal() {
        vvsetIsOpen(true);
    }

    const history = useHistory();

    const handleClickRR = () => {
        history.push(`/recipe-recommender`);
    }

    const handleClickVV = () => {
        history.push("/portfolio/violence-visualilzer");
    }


    function vvcloseModal(){
        vvsetIsOpen(false);
    }

    return (
        <div id='portfolio'>
            <NavBar/>
            <Header>
                Recent Projects
                <hr style={{borderTop: '3px solid #bbb', width: '90%'}}></hr>
            </Header>
            <Fade bottom>
            <Header className='mx-5' style={{letterSpacing: '.1em', fontSize: '2vw', margin: '2em', marginBottom: '1px', color: 'grey'}}>
                Here are two web apps I've built from the ground up:
            </Header>
            </Fade>
            <Fade bottom distance='20%'>
            <div class="container-fluid">
                <div class="row" style={{margin: '1em', padding: '2em', paddingRight: '4em', display: 'flex'}}>
                    <div class="col-sm-6">
                        <Link to ="/portfolio/recipe-recommender">
                            <Card bg="white"  style={{ margin: '1rem', border: 'solid #2f2f2f', cursor: 'pointer'}} onClick={handleClickRR}>
                                <Card.Img variant="top" src="/recipe-recommender.png" alt="recipe-recommender" height='50%' width="50%" object-fit='cover'/>
                                <Card.Img variant="top" src="/rr-label.png" alt="rr-label" height='50%' width="50%" object-fit='cover'/>
                                {/*<Card.Body style={{position: 'relative'}}>*/}
                                {/*    <Card.Title className='mx-5'><Title>RECIPE-RECOMMENDER</Title></Card.Title>*/}
                                {/*</Card.Body>*/}
                            </Card>
                        </Link>
                        <Modal
                            isOpen={rrmodalIsOpen}
                            onRequestClose={rrcloseModal}
                            style={customStyles}
                        >
                            <Card bg="white"  style={{ margin: '1rem', border: 'solid #2f2f2f'}} onClick={rrcloseModal}>
                                <Card.Body style={{position: 'relative'}}>
                                    <Card.Title className='ml-5 my-5'><TitleText><a href="https://hoeunsim.com/rr/" target="_blank" rel="noopener noreferrer">RECIPE-RECOMMENDER</a></TitleText></Card.Title>
                                    <Card.Text className='mx-5' style={{fontSize: '1em'}}>
                                        <p>Outside of coding, I love cooking and experimenting with new recipes. To share this passion and gain new web development skills,
                                            I built Recipe-Recommender, a full-stack web app for finding recipes and discovering new cuisines, dishes, and drinks. <br/><br/>
                                            Currently, the search engine contains over 91,000 recipes from <a href="https://www.allrecipes.com/" target="_blank" rel="noopener noreferrer">Allrecipes</a>.
                                            Users may search for recipes based on ingredients or recipe names, then view the results, displayed as cards in a Pinterest-like grid.
                                            When a recipe card is selected, that recipe’s modal window will pop up, showing more information about the recipe, including a list of ingredients and cooking instructions.
                                            Optionally, users may sort recipe results by rating, time, or reviews.<br/><br/>

                                            TL;DR: Recipe-Recommender helps you find recipes and get cookin’!<br/><br/>
                                        </p>
                                        <hr></hr>
                                            <SmallText>
                                                <p><b>Front-end</b>: ReactJS | JavaScript | jQuery | HTML5 | CSS | Bootstrap | Material-UI</p>
                                                <p><b>Back-end</b>: Python | Pytest | Flask | REST APIs | JSON | SQLite | SQLAlchemy</p>
                                                <p><b>Tools</b>: Git | GitHub | npm | GNU Make | Notion | UNIX/Linux | Postman | IntelliJ IDEA | Vim</p>
                                            </SmallText>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                    </Modal>
                    </div>
                    <div className="col-sm-6">
                        <Link to="/portfolio/violence-visualizer">
                            <Card bg="white"  style={{ margin: '1rem', border: 'solid #2f2f2f', cursor: 'pointer'}} onClick={handleClickVV}>
                                <Card.Img variant="top" src="/violence-visualizer.png" alt="violence-visualizer" height='50%' width="50%" object-fit='cover'/>
                                <Card.Img variant="top" src="/vv-label.png" alt="vv-label" height='50%' width="50%" object-fit='cover'/>
                                {/*<Card.Body style={{position: 'relative'}}>*/}
                                {/*    <Card.Title className='mx-5'><Title>VIOLENCE-VISUALIZER</Title></Card.Title>*/}
                                {/*</Card.Body>*/}
                            </Card>
                        </Link>


                        <Modal
                            isOpen={vvmodalIsOpen}
                            onRequestClose={vvcloseModal}
                            style={customStyles}
                        >

                        <Card bg="white"  style={{ margin: '1rem', border: 'solid #2f2f2f'}} onClick={vvcloseModal}>
                            <Card.Body style={{position: 'relative'}}>
                                <Card.Title className='ml-5 my-5'><TitleText><a href="https://hoeunsim.com/vt/" target="_blank" rel="noopener noreferrer">VIOLENCE-VISUALIZER</a></TitleText></Card.Title>
                                <Card.Text className='mx-5' style={{fontSize: '1em'}}>
                                    <p>Following the death of George Floyd, a wave of civil unrest escalated across the nation. As thousands of protesters took to the
                                        streets, demanding an end to racial injustice and police brutality, evidence of violent police crackdowns
                                        on peaceful demonstrators began circulating the web. Since May, 2020, over <a href="https://www.reddit.com/r/2020PoliceBrutality/" target="_blank" rel="noopener noreferrer">1,200 incidents have been documented</a>,
                                        with videos showing police engaging in unprovoked and unnecessary harassment, assaults, and brutality. <br/><br/>

                                        To highlight the policing crisis, I helped lead a team of aspiring developers from City College of San Francisco’s AI (Artificial Intelligence) Club
                                        to build Violence-Visualizer, a web app for visualizing and tracking reported incidents of police violence during the 2020 George Floyd protests.
                                        The app displays the incidents as markers on an interactive map. Visitors may learn more about each incident by clicking on the map markers,
                                        which will open a sidebar containing more detailed information about the incident, including sources and related evidence.<br/><br/>
                                       </p>
                                    <hr></hr>
                                    <SmallText>
                                        <p><b>Front-end</b>: ReactJS | JavaScript | Leaflet | HTML5 | CSS | Figma | Material-UI</p>
                                        <p><b>Back-end</b>: Python | Pytest | Flask | GraphQL | JSON | PostgreSQL | SQLAlchemy</p>
                                        <p><b>Tools</b>: Git | GitHub | npm | GNU Make | Trello | UNIX/Linux | Docker | IntelliJ IDEA | Vim</p>
                                    </SmallText>
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        </Modal>

                    </div>
                </div>
            </div>
            </Fade>
            <hr style={{borderTop: '2px solid #bbb', width: '90%'}}></hr>
        </div>
    );
}
