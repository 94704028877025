import React from 'react';
import ResponsiveGallery from 'react-responsive-gallery';
import {Header} from "./Portfolio";
import Fade from "react-reveal/Fade";


export function Skills() {
    const images=[
        {
            src: 'python.png'
        },
        {
            src: 'c++.png'
        },
        {
            src: 'java.png'
        },
        {
            src: 'react.jpeg'
        },
        {
            src: 'mysql.png'
        },
        {
            src: 'sqlite.png'
        },
        {
            src: 'postgresql.png'
        },
        {
            src: 'sqlalchemy.jpg'
        },
        {
            src: 'git-github.png'
        },
        {
            src: 'html-css-js.jpeg'
        },
        {
            src: 'bootstrap.jpeg'
        },
        {
            src: 'rest.png'
        },
        {
            src: 'docker.png'
        },
        {
            src: 'vim.png'
        },
        {
            src: 'intellij.png'
        },
        {
            src: 'make.png'
        },
        {
            src: 'pytest.png'
        },

        {
            src: 'graphql.png'
        },
        {
            src: 'flask.png'
        },
        {
            src: 'linux-UNIX.jpg'
        },


    ];
    return (
        <div id="About" >
            <Header>
                Skills
                <hr style={{borderTop: '2px solid #bbb', width: '90%'}}></hr>
            </Header>
            <Fade bottom>
            <Header className='mx-5' style={{letterSpacing: '.1em', fontSize: '2vw', margin: '2em', color: 'grey'}}>
                I’ve picked up some skills from taking online and community college courses, attending workshops, building projects, and lots and lots of Googling!
            </Header>
            </Fade>
            <Fade bottom distance='20%'>
            <div style={{width: '60%', marginLeft: '20%'}}>
                <ResponsiveGallery images={images}/>
            </div>
            </Fade>
            <hr style={{borderTop: '2px solid #bbb', width: '90%'}}></hr>

        </div>
    );
}


