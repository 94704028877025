import React, { Component } from 'react';
import styled from 'styled-components';
import Card from 'react-bootstrap/Card';
import Nav from "react-bootstrap/Nav";
import {TitleText} from "./About";


const Title = styled(TitleText)`
    text-align: center;
    margin: 1px;  
`;

export function Blog() {
    return (
        <div id="Blog" >
            <Title>
                The Blog
                <hr style={{borderTop: '3px solid #bbb', width: '90%'}}></hr>
            </Title>
        </div>
    );
}