import React, { Component } from 'react';
import styled from 'styled-components';
import Card from 'react-bootstrap/Card';
import Nav from "react-bootstrap/Nav";
import {TitleText} from "./About";
import Button from "@material-ui/core/Button";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Fade from "react-reveal/Fade";
import {Header} from "./Portfolio"
import CopyrightIcon from '@material-ui/icons/Copyright';
import {NavBar} from "./NavBar";

export function Contact() {
    function sendEmail() {
        window.location = "mailto:hoeun.sim00@gmail.com"
    }
    function linkLinkedIn() {
        window.open(
            "https://www.linkedin.com/in/hoeun-sim-9b195435/", "_blank");
    }
    function linkGitHub() {
        window.open(
            "https://github.com/Hoeuuun/", "_blank");
    }
        return (
        <div id="contact" >
            <NavBar />
            <Header>
                Contact Me
                <hr style={{borderTop: '3px solid #bbb', width: '90%'}}></hr>
            </Header>

            <Header style={{letterSpacing: '.1em', fontSize: '2.5vw', margin: '2.5em', color: 'grey'}}>
                <Fade bottom>
                    <p>Thanks for checking out my website.</p>
                    <p>Interested in working together?</p>
                    <p>Let's get in touch!</p>
                </Fade>
                <Fade bottom>
                        <div style={{margin:'3em'}}>
                            <Button onClick={sendEmail} size="large">
                                <MailOutlineIcon style={{fontSize: '4em'}}></MailOutlineIcon>
                            </Button>

                            <Button onClick={linkGitHub} size="large">
                                <GitHubIcon style={{fontSize: '4em'}}></GitHubIcon>
                            </Button>

                            <Button onClick={linkLinkedIn} size="large">
                                <LinkedInIcon style={{fontSize: '4em'}}></LinkedInIcon>
                            </Button>
                        </div>
                </Fade><br/><br/><br/>
                <p style={{fontSize:'1.5vw'}}><CopyrightIcon style={{fontSize: 'medium'}}></CopyrightIcon> 2020 Hoeun Sim</p>
            </Header>
        </div>
    );
}