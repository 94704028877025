import React, { Component } from 'react';
import styled from 'styled-components';
import Card from 'react-bootstrap/Card';
import Nav from "react-bootstrap/Nav";
import {TitleText} from "./About";
import Fade from "react-reveal/Fade";
import Modal from "react-modal";
import {SmallText} from "./Portfolio";
import {NavBar} from "./NavBar";
import GitHubIcon from "@material-ui/icons/GitHub";
import Button from "@material-ui/core/Button";

export function RR() {
    function linkGitHub() {
        window.open(
            "https://github.com/Hoeuuun/recipe-recommender", "_blank");
    }
    return (
        <div id="recipe-recommender">
            <Fade bottom distance='20%'>
                <Card bg="white"  style={{ margin: '1rem', border: 'solid #2f2f2f'}} >
                    <Card.Body style={{position: 'relative'}}>
                        <Card.Title className='ml-5 my-5'>
                            <TitleText><a href="https://hoeunsim.com/rr/" target="_blank" rel="noopener noreferrer">RECIPE-RECOMMENDER
                            <Button style={{float: 'right'}} onClick={linkGitHub} size="large">
                            <GitHubIcon style={{fontSize: '2.3em'}}></GitHubIcon>
                            </Button></a></TitleText>

                        </Card.Title>
                        <Card.Text className='mx-5' style={{fontSize: '1em'}}>
                            <p>Outside of coding, I love cooking and experimenting with new recipes. To share this passion and gain new web development skills,
                                I built Recipe-Recommender, a full-stack web app for finding recipes and discovering new cuisines, dishes, and drinks. <br/><br/>
                                Currently, the search engine contains over 91,000 recipes from <a href="https://www.allrecipes.com/" target="_blank" rel="noopener noreferrer">Allrecipes</a>.
                                Users may search for recipes based on ingredients or recipe names, then view the results, displayed as cards in a Pinterest-like grid.
                                When a recipe card is selected, that recipe’s modal window will pop up, showing more information about the recipe, including a list of ingredients and cooking instructions.
                                Optionally, users may sort recipe results by rating, time, or reviews.<br/><br/>

                                TL;DR: Recipe-Recommender helps you find recipes and get cookin’!<br/><br/>
                            </p>
                            <hr></hr>
                            <SmallText>
                                <p><b>Front-end</b>: ReactJS | JavaScript | jQuery | HTML5 | CSS | Bootstrap | Material-UI</p>
                                <p><b>Back-end</b>: Python | Pytest | Flask | REST APIs | JSON | SQLite | SQLAlchemy</p>
                                <p><b>Tools</b>: Git | GitHub | npm | GNU Make | Notion | UNIX/Linux | Postman | IntelliJ IDEA | Vim</p>
                            </SmallText>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Fade>
            <hr style={{borderTop: '2px solid #bbb', width: '90%'}}></hr>
        </div>
    );
}