import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {Container} from "react-bootstrap";
import React from "react";
import Button from "@material-ui/core/Button";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Fade from "react-reveal/Fade";
import {Contact} from "./Contact";
import {Link} from "react-router-dom";

import { NavHashLink } from 'react-router-hash-link';
import { HashLink } from 'react-router-hash-link';

import styled from 'styled-components';


const StyledLink = styled(Link)`
    text-decoration: none;
    
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        color: grey;
    }
    
     &:hover, &:focus{
        text-decoration: none;
        color: #101010;
    }
    
     &:active {
        text-decoration: none;
        color: #101010;
    }
}
`;


export function NavBar() {
    function sendEmail() {
        window.location = "mailto:hoeun.sim00@gmail.com"
    }
    function linkLinkedIn() {
        window.open(
            "https://www.linkedin.com/in/hoeun-sim-9b195435/", "_blank");
    }
    function linkGitHub() {
        window.open(
            "https://github.com/Hoeuuun/", "_blank");
    }
    return (
        <div>
            <Navbar bg="light" expand="sm" fixed='top'>
                <Navbar.Brand>
                    <div>
                        <Button onClick={sendEmail} size="large">
                            <MailOutlineIcon style={{fontSize: '2.3em'}}></MailOutlineIcon>
                        </Button>

                        <Button onClick={linkGitHub} size="large">
                            <GitHubIcon style={{fontSize: '2.3em'}}></GitHubIcon>
                        </Button>

                        <Button onClick={linkLinkedIn} size="large">
                            <LinkedInIcon style={{fontSize: '2.3em'}}></LinkedInIcon>
                        </Button>
                    </div>
                </Navbar.Brand>

                <Container>

                {/*<Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>*/}
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" >
                        <Nav className="mx-auto" style={{fontSize: "1.2em",
                                                        letterSpacing: "5px",
                                                        margin: "1em"
                                                        }}>

                                {/*<HashLink smooth to="#about" className="mr-5" style={{color: 'grey'}}>about</HashLink>*/}
                            <StyledLink smooth to="/about" className="mr-5">about</StyledLink>
                                {/*<Nav.Link href="#Blog" className="mr-5">blog</Nav.Link>*/}
                            <StyledLink to="/portfolio" className="mr-5">portfolio</StyledLink>
                                {/*<HashLink smooth to="#contact" style={{color: 'grey'}}>contact</HashLink>*/}
                            <StyledLink smoth to="/contact">contact</StyledLink>
                        </Nav>
                </Navbar.Collapse>
                </Container>

            </Navbar>
            <hr style={{borderTop: '3px solid #bbb', width: '90%'}}></hr>
        </div>
    );
}